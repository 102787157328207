import React, { useRef } from "react"
import { Link } from "gatsby"
import Slider from "react-slick"

const Hero = ({ data }) => {
  const { banner } = data

  var settings = {
    dots: false,
    arrows: true,
    autoplay: true,
    draggable: true,
    autoplaySpeed: 6000,
    initialSlide: 0,
  }

  const slider = useRef(null)

  return (
    <div className="hero_banner_section">
      <div className="container">
        <div className="hero_banner_image">
          <Slider ref={slider} {...settings}>
            {banner.images.map(image => (
              <div className="hero_slide" key={image.id}>
                <img src={image.file?.url} alt="Hero Image" />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div className="hero_banner_content">
        <div className="container">
          <div className="hero_banner_text">
            <div className="hero_banner_title">
              <h1>
                {banner.title} <br />
                <span>{banner.headlineL2}</span><br className="safariOnly"/>
                <b>{banner.titleOptional}</b>
              </h1>
            </div>
            <div className="hero_banner_desc">
              <p>{banner.description.description}</p>
            </div>
            <div className="common_section_btn">
              <a href={banner.url} className="common_btn">
                {banner.label}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="38.938"
                  height="8.071"
                  viewBox="0 0 38.938 8.071"
                >
                  <g id="right-arrow" transform="translate(-1192.844 -692.433)">
                    <path
                      id="Icon_open-arrow-left"
                      data-name="Icon open-arrow-left"
                      d="M3.561,0,0,3,3.561,5.935V3.561h34.1V2.374H3.561Z"
                      transform="translate(1231 699.427) rotate(180)"
                      fill="#fff"
                    />
                    <path
                      id="Icon_open-arrow-left_-_Outline"
                      data-name="Icon open-arrow-left - Outline"
                      d="M4.061-1.076v2.95h34.1V4.061H4.061V6.995L-.781,3.008Zm33.1,3.95H3.061v-1.8L.781,3l2.28,1.877V3.061h34.1Z"
                      transform="translate(1231 699.427) rotate(180)"
                      fill="#fff"
                    />
                  </g>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
        </div>
  )
}

export default Hero
