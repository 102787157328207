import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { fullDate } from "../../utils/date_format"
import moment from 'moment';
import 'moment/locale/fr';

const fullDateInEnglish = (date) => {
  const currentLocale = moment.locale(); // Store the current locale
  moment.locale('en'); // Temporarily set locale to English
  const formattedDate = moment(date).format('MMM DD YYYY'); // Format date
  moment.locale(currentLocale); // Reset locale to original
  return formattedDate;
};

export const FRmonthYearFormat = (date) => {
  let formattedDate = moment(date).locale('fr').format('MMM DD YYYY');
  formattedDate = formattedDate.replace(/\./g, '');
  return formattedDate.toUpperCase();
};

const RecentNews = () => {
  const { englishNews, frenchNews } = useStaticQuery(graphql`
    query {
      frenchNews: allContentfulNews(
        sort: { fields: publishedAt, order: DESC }
        limit: 34
        filter: { node_locale: { eq: "fr" } }
      ) {
        nodes {
          id
          title
          slug
          frenchText
          image {
            gatsbyImageData(layout: CONSTRAINED, width: 600)
          }
          publishedAt
          excerpt {
            excerpt
          }
          externalLink
        }
      }
    }
  `);

  // Deduplication function
  const deduplicateNews = newsItems => {
    const uniqueNews = new Map();
    newsItems.forEach(item => {
      // Using a combination of title and publishedAt as a unique key
      const uniqueKey = `${item.title}-${item.publishedAt}`;
      if (!uniqueNews.has(uniqueKey)) {
        uniqueNews.set(uniqueKey, item);
      }
    });
    return Array.from(uniqueNews.values());
  };

  // Apply deduplication
  const deduplicatedFrenchNews = deduplicateNews(frenchNews.nodes);

  return (
    <div className="recent_news_section fr-only">
      <div className="container">
        <div className="recent_news_block">
          <div className="common_section_title">
            <h2 className="fr-only">
              Nouvelles <span>Récentes</span>
            </h2>
          </div>
          <div className="recent_news_slider_block">
            <div className="recent_news_slick fr-only">
              {deduplicatedFrenchNews
                .filter(news => news.frenchText == "French")
                .slice(0, 3)
                .map(news => (
                  <div className={`item ${news.frenchText}`} key={news.id}>
                    <div className="recent_news_box">
                      <div className="recent_news_img">
                        {news.externalLink?.startsWith("http") ? (
                          <a
                            href={news.externalLink}
                            rel="noreferrer"
                            target="_blank"
                          >
                            <GatsbyImage
                              image={getImage(news.image)}
                              alt={news.title}
                            />
                          </a>
                        ) : (
                          <Link to={`/news/${news.slug}`}>
                            <GatsbyImage
                              image={getImage(news.image)}
                              alt={news.title}
                            />
                          </Link>
                        )}
                      </div>
                      <div className="recent_news_content">
                        <p className="rn_date_label">
                          {FRmonthYearFormat(news.publishedAt)}
                        </p>
                        <h3 className="rn_title_text">
                          {news.externalLink?.startsWith("http") ? (
                            <a href={news.externalLink} rel="noreferrer" target="_blank">
                              {news.title}
                            </a>
                          ) : (
                            <Link to={`/news/${news.slug}`}>
                              {news.title}
                            </Link>
                          )}
                        </h3>
                        <p className="cinfo">{news.excerpt.excerpt}</p>
                        <div className="educion_learn_more_btn">
                          {news.externalLink?.startsWith("http") ? (
                            <a href={news.externalLink} rel="noreferrer" target="_blank">
                              Lire la Suite <i className="fa fa-caret-right" aria-hidden="true"></i>
                            </a>
                          ) : (
                            <Link to={`/news/${news.slug}`}>
                              Lire la Suite <i className="fa fa-caret-right" aria-hidden="true"></i>
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <Link to={'/news'} className="read-more-news-btn fr-only">
          Plus de presse
        </Link>
      </div>
    </div>
  );
};

export default RecentNews;
