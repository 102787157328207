import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const About = ({ data }) => {
  const {
    section3Title1,
    section3Title2,
    section3Description,
    section3ImageBackground,
    section3ImageFront,
    section3ImageShapes,
    section3Button,
  } = data
  return (
    <div className="about_commb_section">
      <div className="about_commb_image_block">
        <GatsbyImage
          image={getImage(section3ImageBackground)}
          className={'largeAboutImgWrapper'}
          alt="background"
        />
        <div className="about_small_img">
          <GatsbyImage image={getImage(section3ImageFront)} alt="front" />
        </div>

        <div className="about_plus_shape_img">
          <img src={section3ImageShapes.file?.url} alt="shape" />
        </div>
      </div>

      <div className="about_commb_content">
        <div className="container">
          <div className="about_commb_details">
            <div className="common_section_title">
              <h2>
                {section3Title1} <span>{section3Title2}</span>
              </h2>
            </div>
            <div className="common_desc">
              <p>{section3Description.section3Description}</p>
            </div>
            <div className="common_section_btn">
              <Link to={section3Button.url} className="newLink">
                {section3Button.label}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
