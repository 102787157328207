import React from "react"
import { Link } from "gatsby"

const Member = ({ data }) => {
  const {
    section7Title,
    section7Description,
    section7Label,
    section7Url,
    becomeMemberSmallNote,
  } = data
  return (
    <div className="become_member_section">
      <div className="container">
        <div className="become_member_content">
          <h3 className="en-only">
            Become a Member and <span> Get Measured </span>
          </h3>
          <h3 className="fr-only">
          Devenez membre et <span>faites-vous évaluer</span>
          </h3>

          {/* NEED TO REFACTOR! <h3>{section7Title}</h3> */}
          {/* NEED TO REFACTOR TO RICHTEXT <p>{section7Description.section7Description}</p> */}
          <p className="en-only">
            Do you want to get market credibility and a competitive edge over
            other OOH companies that are not members? Or have access to COMMB's
            planning resources: COMMBNavigator®* and the COMMB Data Report that
            are used by planners to evaluate and develop OOH campaigns? Then
            join COMMB today!
          </p>
          <p className="fr-only">
            Vous souhaitez obtenir plus de crédibilité dans le marché? Un avantage concurrentiel par rapport aux non-membres? Un accès à des outils de planification et d’évaluation de campagnes comme COMMBNavigatorMD* et les rapports de données COMMB? Il suffit de devenir membre!
          </p>
          <p className="en-only">
            <small style={{ fontSize: "12px" }}>{becomeMemberSmallNote}</small>
          </p>
          <p className="fr-only">
            <small style={{ fontSize: "12px" }}>*Attention : Il faut être membre de Numeris pour accéder à COMMBNavigatorMD.</small>
          </p>
          <Link to={section7Url} className="home_sign_up">
            {section7Label}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Member
