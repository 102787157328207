import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { fullDate } from "../../utils/date_format"
import moment from 'moment';
import 'moment/locale/fr';

const fullDateInEnglish = (date) => {
  const currentLocale = moment.locale(); // Store the current locale
  moment.locale('en'); // Temporarily set locale to English
  const formattedDate = moment(date).format('MMM DD YYYY'); // Format date
  moment.locale(currentLocale); // Reset locale to original
  return formattedDate;
};

export const FRmonthYearFormat = (date) => {
  let formattedDate = moment(date).locale('fr').format('MMM DD YYYY');
  formattedDate = formattedDate.replace(/\./g, '');
  return formattedDate.toUpperCase();
};


const RecentNews = () => {
  const { allContentfulNews } = useStaticQuery(graphql`
    {
      allContentfulNews(sort: { fields: publishedAt, order: DESC }, limit: 34) {
        nodes {
          id
          title
          frenchText
          slug
          image {
            gatsbyImageData
          }
          publishedAt
          excerpt {
            excerpt
          }
          externalLink
        }
      }
    }
  `)

  // Deduplication function
  const deduplicateNews = newsItems => {
    const uniqueNews = new Map();
    newsItems.forEach(item => {
      // Using a combination of title and publishedAt as a unique key
      const uniqueKey = `${item.title}-${item.publishedAt}`;
      if (!uniqueNews.has(uniqueKey)) {
        uniqueNews.set(uniqueKey, item);
      }
    });
    return Array.from(uniqueNews.values());
  };

  // Apply deduplication
  const deduplicatedNews = deduplicateNews(allContentfulNews.nodes);

  return (
    <div className="recent_news_section en-only">
      <div className="container">
        <div className="recent_news_block">
          <div className="common_section_title">
            <h2 className="en-only">
              Recent <span>News</span>
            </h2>
          </div>
          <div className="recent_news_slider_block">
            <div className="recent_news_slick en-only">
                {deduplicatedNews
                  .filter(news => news.frenchText != "French") // Filter for English  news only
                  .slice(0, 3) 
                  .map(news => (
                    <div className="item" key={news.id}>
                      <div className="recent_news_box">
                        <div className="recent_news_img">
                          {news.externalLink?.startsWith("http") ? (
                            <a
                              href={news.externalLink}
                              rel="noreferrer"
                              target="_blank"
                            >
                              <GatsbyImage
                                image={getImage(news.image)}
                                alt={news.title}
                              />
                            </a>
                          ) : (
                            <Link to={`/news/${news.slug}`}>
                              <GatsbyImage
                                image={getImage(news.image)}
                                alt={news.title}
                              />
                            </Link>
                          )}
                        </div>
                        <div className="recent_news_content">
                          <p className="rn_date_label">
                            <span className="en-only">{fullDateInEnglish(news.publishedAt)}</span>
                            <span className="fr-only">{fullDate(news.publishedAt)}</span>
                          </p>
                          <h3 className="rn_title_text">
                            {news.externalLink?.startsWith("http") ? (
                              <a href={news.externalLink} rel="noreferrer" target="_blank">
                                {news.title}
                              </a>
                            ) : (
                              <Link to={`/news/${news.slug}`}>
                                {news.title}
                              </Link>
                            )}
                          </h3>
                          <p className="cinfo">{news.excerpt.excerpt}</p>
                          <div className="educion_learn_more_btn">
                            {news.externalLink?.startsWith("http") ? (
                              <a href={news.externalLink} rel="noreferrer" target="_blank">
                                Read More <i className="fa fa-caret-right" aria-hidden="true"></i>
                              </a>
                            ) : (
                              <Link to={`/news/${news.slug}`}>
                                Read More <i className="fa fa-caret-right" aria-hidden="true"></i>
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                ))}
            </div>
          </div>
        </div>
        <Link to={'/news'} className="read-more-news-btn en-only">
          More Press
        </Link>
      </div>
    </div>
  )
}

export default RecentNews
