import React from "react"
import { Link } from "gatsby"

const Education = ({ data }) => {
  const { education } = data;
  
  return (
    <div className="education_training_section_wrapper">
      <div className="education_training_section">
        <div className="container">
          <div className="education_training_block">
            {education.map((educationItem) => {
              // Check if the necessary fields are available
              const hasImage = educationItem?.image?.file?.url;
              const hasUrl = educationItem?.url;
              const isExternalLink = hasUrl && educationItem.url.startsWith("http");
              const wrapperClass = `home-media-block-wrapper ${
                hasImage ? "" : "no-image"
              }`;

              // Render the content based on the link type
              return hasUrl ? (
                isExternalLink ? (
                  <a
                    href={educationItem.url}
                    rel="noreferrer"
                    target="_blank"
                    key={educationItem.id}
                    className={wrapperClass}
                  >
                    {hasImage ? (
                      <img
                        src={educationItem.image.file.url}
                        alt="education"
                        className="home-media-block"
                      />
                    ) : (
                      <div className="placeholder-image">Image not available</div>
                    )}
                  </a>
                ) : (
                  <Link
                    to={educationItem.url}
                    key={educationItem.id}
                    className={wrapperClass}
                  >
                    {hasImage ? (
                      <img
                        src={educationItem.image.file.url}
                        alt="education"
                        className="home-media-block"
                      />
                    ) : (
                      <div className="placeholder-image">Image not available</div>
                    )}
                  </Link>
                )
              ) : (
                <div key={educationItem.id} className={`${wrapperClass} no-link`}>
                  {hasImage ? (
                    <img
                      src={educationItem.image.file.url}
                      alt="education"
                      className="home-media-block"
                    />
                  ) : (
                    <div className="placeholder-image">Image not available</div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <span className="scroll-right mobileOnly">Scroll right</span>
    </div>
  );
};

export default Education;
