import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Modal from "react-modal"
import Select from "react-select"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const BookCommb = ({ data }) => {
  const [values, setValues] = useState({
    name: "",
    email: "",
    orgName: "",
    trainingType: "",
    amountofPeople: "",
    trainingDate: new Date(),
  })
  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)

  const { name, email, orgName, trainingType, amountofPeople, trainingDate } =
    values

  const handleChange = e =>
    setValues({ ...values, [e.target.name]: e.target.value })

  const onChange = selectedOption => {
    setValues({ ...values, trainingType: selectedOption })
    // console.log(`Option selected:`, selectedOption);
  }

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  const handleSubmit = async e => {
    e.preventDefault()
    
    setError("")
    setSuccess(false)

    if (
      !name ||
      !email ||
      !orgName ||
      !trainingType.value ||
      !amountofPeople ||
      !trainingDate
    ) {
      setError("All fields are required")
      return
    }

    if (!validateEmail(email)) {
      setError("Please provide a valid email")
      return
    }
    try {
      const res = await fetch("/.netlify/functions/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
      if (res.ok) {
        setSuccess(true);
        document
          .querySelector("form.book")
          .classList.add("formSubmitted");
        document
          .querySelector("form.indoor book").disabled = true;
      }
    } catch (err) {
      console.log(err)
      setError("Something went wrong")
    }
  }
  
  const {
    section9Title,
    section9ShortText,
    bookCommb,
    section9Image1,
    section9Image3,
  } = data

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "transparent",
      border: "1px solid #fff",
      color: "red",
      // match with the menu
      borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#bf1116" : "#bf1116",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "#bf1116" : "#bf1116",
      },
    }),
    menu: base => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      border: "1px solid #fff",
      // kill the gap
      marginTop: 0,
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      background: "#151515",
      color: "#bf1116",
    }),
  }

  const options = [
    { value: "Speaking", label: "Speaking" },
    { value: "Conference", label: "Conference" },
    { value: "Sales Team", label: "Sales Team" },
    { value: "Classroom", label: "Classroom" },
  ]

  const trainingOptions = [
    { value: "CDR", label: "CDR" },
    { value: "COMMBNavigator®", label: "COMMBNavigator®" },
    { value: "Measurement 101", label: "Measurement 101" },
    { value: "OOH 101", label: "OOH 101" },
  ]

  const [idx, setIdx] = useState(null)
  const [modalIsOpen, setIsOpen] = useState(false)
  function openModal(i) {
    setIdx(i)
    setIsOpen(true)
  }
  function closeModal() {
    setIsOpen(false)
  }

  Modal.setAppElement("#___gatsby")

  useEffect(() => {
    if (window.location.href.indexOf("bookCOMMB") > -1) {
      // console.log("modal 1");
      openModal(0);
    }
    else if (window.location.href.indexOf("bookTraining") > -1) {
      // console.log("modal 2");
      openModal(1);
    };
    
  }, []);

  const [startDate, setStartDate] = useState(new Date())
  return (
    <div className="book_commb_today_section">
      <div className="container">
        <div className="book_commb_today_block">
          <div className="row align-items-center">
            <div className="col-lg-6 cm_lg_col_6">
              <div className="book_commb_today_content">
                <div className="commn_title_label">
                  <p>{section9ShortText}</p>
                </div>
                <div className="common_inner_title">
                  <h3>{section9Title}</h3>
                </div>
                <div className="common_desc">
                  {bookCommb.map((item, i) => (
                    <div key={item.id}>
                      <p>{item.description.description}</p>
                      <div className="educion_learn_more_btn">
                        <a onClick={() => openModal(i)}>
                          <Link>{item.label} </Link>
                        </a>
                      </div>
                      {modalIsOpen && i === 0 && (
                        <Modal
                          isOpen={idx === i}
                          onRequestClose={closeModal}
                          contentLabel={i.toString()}
                          id={i.toString()}
                          key={i}
                          testId={i.toString()}
                          bodyOpenClassName={`BookCOMMBModal modalOpen`}
                        >
                          <div className="common_section_title">
                            <h2 className="en-only">
                              Book <span> COMMB</span>
                            </h2>
                            <h2 className="fr-only">
                              Engagez<span> -nous</span>
                            </h2>
                            <p className="bookCOMMBp en-only">
                              Need COMMB To Speak At Your Event?
                            </p>
                            <p className="bookCOMMBp fr-only">
                              Invitez COMMB à votre événement
                            </p>
                          </div>
                          <form onSubmit={handleSubmit} className="bookToSpeak">
                            <input
                              type="text"
                              placeholder="Full Name"
                              required
                              className="textInput"
                              name="name"
                              value={name}
                              onChange={handleChange}
                            />
                            <input
                              type="text"
                              placeholder="Email Address"
                              required
                              className="textInput"
                              name="email"
                              value={email}
                              onChange={handleChange}
                            />
                            <input
                              type="text"
                              placeholder="Organization Name"
                              required
                              className="textInput"
                              name="orgName"
                              value={orgName}
                              onChange={handleChange}
                            />
                            <Select
                              options={options}
                              className={`customSelect custom-option`}
                              styles={customStyles}
                              required
                              placeholder={`Select Booking Type`}
                              value={trainingType}
                              onChange={onChange}
                            />
                            <input
                              type="text"
                              className="hidden"
                              name="trainingType"
                              value={trainingType.value}
                            />
                            <DatePicker
                              selected={trainingDate}
                              placeholder={`When would you like training to occur?`}
                              name="trainingDate"
                              // value={trainingDate}
                              onChange={date => setValues({ ...values, trainingDate: date })}
                            />
                            <label className="hidden">Event Details/Topic/Agenda</label>
                            <textarea
                              type="text"
                              placeholder="What are the Details/Topic/Agenda of the Event?"
                              required
                              className="textInput"
                              name="amountofPeople"
                              value={amountofPeople}
                              onChange={handleChange}
                            />
                            <button className="submit"><span className="submitText">Submit</span>
                                {success && ( <span className="thankYouMsg">
                                    Thank you for contacting us! We'll be in touch
                                    with you soon.</span> )}
                            </button>
                          </form>
                          <a className="closeBtnModal" onClick={closeModal}>
                            {" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="17.688"
                              height="17.688"
                              viewBox="0 0 17.688 17.688"
                            >
                              <path
                                id="close-icon"
                                d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                                transform="translate(-7.5 -7.5)"
                                fill="#fff"
                              ></path>
                            </svg>
                          </a>
                        </Modal>
                      )}

                      {modalIsOpen && i === 1 && (
                        <Modal
                          isOpen={idx === i}
                          onRequestClose={closeModal}
                          contentLabel={i.toString()}
                          id={i.toString()}
                          key={i}
                          testId={i.toString()}
                          bodyOpenClassName={`BookCOMMBModal modalOpen trainingModal`}
                        >
                          <div className="common_section_title">
                            <h2 className="en-only">
                              Require <span> Training?</span>
                            </h2>
                            <h2 className="fr-only">
                              Besoin de <span>Formation?</span>
                            </h2>
                          </div>
                          <form onSubmit={handleSubmit} className="requireTraining">
                            <input
                              type="text"
                              placeholder="Full Name"
                              required
                              className="textInput"
                              name="name"
                              value={name}
                              onChange={handleChange}
                            />
                            <input
                              type="text"
                              placeholder="Email Address"
                              required
                              className="textInput"
                              name="email"
                              value={email}
                              onChange={handleChange}
                            />
                            <input
                              type="text"
                              placeholder="Organization Name"
                              required
                              className="textInput"
                              name="orgName"
                              value={orgName}
                              onChange={handleChange}
                            />
                            <Select
                              options={trainingOptions}
                              className={`customSelect custom-option`}
                              styles={customStyles}
                              required
                              placeholder={`Select Training Type`}
                              value={trainingType}
                              onChange={onChange}
                            />
                            <input
                              type="text"
                              className="hidden"
                              name="trainingType"
                              value={trainingType.value}
                            />
                            <input
                              type="text"
                              placeholder="Amount of People"
                              required
                              className="textInput"
                              name="amountofPeople"
                              value={amountofPeople}
                              onChange={handleChange}
                            />
                            <p>When would you like training to occur?</p>
                            <DatePicker
                              selected={trainingDate}
                              placeholder={`When would you like training to occur?`}
                              name="trainingDate"
                              // value={trainingDate}
                              onChange={date => setValues({ ...values, trainingDate: date })}
                            />
                            <button className="submit"><span className="submitText">Submit</span>
                                {success && ( <span className="thankYouMsg">
                                    Thank you for contacting us! We'll be in touch
                                    with you soon.</span> )}
                            </button>
                          </form>
                          <a className="closeBtnModal" onClick={closeModal}>
                            {" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="17.688"
                              height="17.688"
                              viewBox="0 0 17.68NE8 17.688"
                            >
                              <path
                                id="close-icon"
                                d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                                transform="translate(-7.5 -7.5)"
                                fill="#fff"
                              ></path>
                            </svg>
                          </a>
                        </Modal>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-lg-6 cm_lg_col_6 cm_order">
              <div className="book_commb_today_">
                <GatsbyImage
                  image={getImage(section9Image1)}
                  alt="book"
                  className="book_img_large"
                />
                <div className="book_commb_inner_img">
                  {/* <GatsbyImage image={getImage(section9Image2)} alt="book" /> */}
                </div>
                <div className="book_commb_shape">
                  <img src={section9Image3.file?.url} alt="Bokk Shape" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BookCommb
